import firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyBeT6-P6T7zmRYrzY7PKXcbnztTJRf6jas",
    authDomain: "arreis-tech-home.firebaseapp.com",
    databaseURL: "https://arreis-tech-home.firebaseio.com",
    projectId: "arreis-tech-home",
    storageBucket: "arreis-tech-home.appspot.com",
    messagingSenderId: "507094323003",
    appId: "1:507094323003:web:a8a26bd4219515ca4e311e",
    measurementId: "G-S2C88BY80W"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export const firestore = firebase.firestore();