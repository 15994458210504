import React, {useState} from 'react';
import logo from './logo.svg';
import './App.css';
import { firestore } from './Services/firebase';
import {FaBars} from 'react-icons/fa';
import {FaInstagram} from 'react-icons/fa';
import firebase from "./Services/firebase";
import {FaLinkedin} from 'react-icons/fa';

function App() {
  const [name,setName] = useState('');
  const [mName,setmName] = useState('');
  const [email,setEmail] = useState('');
  const [mEmail,setmEmail] = useState('');
  const [message,setMessage] = useState('');
  const [phone,setPhone] = useState('');
  const [service,setService] = useState('');

  const handleRequest = (event) => {
    event.preventDefault();
    if(name !== "" && email !== "" && phone !== "" && service !== "") {
      firestore.collection("Requests").add({name:name,email:email,type:service,phone:phone,message:message})
      .then(() => setName(""))
      .then(() => setEmail(""))
      .then(() => setPhone(""))
      .then(() => setService(""))
      .then(() => setMessage(""));
    } 
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if(mName !== "" && mEmail !== "" && message !== "") {
      firestore.collection("Messages").add({name:mName,email:mEmail,message:message})
      .then(() => setmName(""))
      .then(() => setmEmail(""))
      .then(() => setMessage(""));
    } 
  }

  return (
    <div className="App">

      <nav className="navbar navbar-expand-md navbar-dark navbar-custom fixed-top">
        <a className="navbar-brand lion-logo" href="index.html"><img src="/lion-tranparent.png" alt="alternative"/></a>
        <a className="navbar-brand logo-image" href="index.html"><img src="/ATlogo3.png" alt="alternative"/></a>

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
            <FaBars />
        </button>

        <div className="collapse navbar-collapse" id="navbarsExampleDefault">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
                <a className="nav-link page-scroll" href="#header">HOME <span class="sr-only">(current)</span></a>
            </li>

            <li className="nav-item">
                <a className="nav-link page-scroll" href="#services">SERVICES</a>
            </li>


            <li className="nav-item">
                <a className="nav-link page-scroll" href="#about">ABOUT</a>
            </li>

            <li className="nav-item">
                <a className="nav-link page-scroll" href="#callMe">CONTACT</a>
            </li>
          </ul>
          <span className="nav-item social-icons">
                <span className="fa-stack">
                    <a href="https://www.instagram.com/arreistech/">
                        <span className="hexagon"></span>
                        <FaInstagram/>
                    </a>
                </span>
            </span>
        </div>
      </nav>

      <header id="header" className="header">
        <div className="header-content">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-container">
                            <h1>EVERY <span id="js-rotating">BUSINESS IS A TECH BUSINESS</span></h1>
                            <p className="p-heading p-large">Arreis Technologies is here to help small minority owned businesses make the fuzzy world of I.T. clear</p>
                            <a className="btn-solid-lg page-scroll" href="#intro">LEARN MORE</a>
                        </div>
                    </div> 
                </div> 
            </div> 
        </div> 
      </header>

      <div id="intro" className="basic-1">
        <div className="container">
            <div className="row">
                <div className="col-lg-5">
                    <div className="text-container">
                        <h2>We Help You Form An I.T. Strategy That Grows With You</h2>
                        <p>Launching a new company or growing an existing one can be hard enough.</p>
                        <p className="testimonial-text">"At Arreis our mission is to provide you with the I.T. support you need to compete in today's world."</p>
                        <div className="testimonial-author">Sheikh Kamarah Jr. - CEO</div>
                    </div> 
                </div> 
                <div className="col-lg-7">
                    <div className="image-container">
                        <img className="img-fluid" src="confused.jpg" alt="alternative"/>
                    </div> 
                </div> 
            </div> 
        </div> 
      </div> 

      <div className="cards-1">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    
                    <div className="card">
                        <span className="fa-stack">
                            <span className="hexagon"></span>
                            <img className="img-fluid" src="information.png" alt="alternative"/>
                        </span>
                        <div className="card-body">
                            <h4 className="card-title">Software Analysis</h4>
                            <p>When it comes to software there are no shortage of options. The right choice can increase revenue, and the wrong choice can derail your business.</p>
                        </div>
                    </div>
                    
                    <div className="card">
                        <span className="fa-stack">
                            <span className="hexagon"></span>
                            <img className="img-fluid" src="strat.png" alt="alternative"/>
                        </span>
                        <div className="card-body">
                            <h4 className="card-title">Strategy Development</h4>
                            <p>Business goals don't always align with I.T. and many businesses must undergo a digital transformation. Save yourself a headache in the future, align now.</p>
                        </div>
                    </div>
                    
                    <div className="card">
                        <span className="fa-stack">
                            <span className="hexagon"></span>
                            <img className="img-fluid" src="coding.png" alt="alternative"/>
                        </span>
                        <div className="card-body">
                            <h4 className="card-title">Building</h4>
                            <p>Have a great idea to grow your business, but don't have the technical know how? We sure do! Building things is our passion, let us help build your passion.</p>
                        </div>
                    </div>
                  

                </div> 
            </div> 
        </div> 
      </div>

      <div id="services" className="cards-2">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h2>Check Out Our Services,<br/> See What Fits Your Needs</h2>
                </div> 
            </div>
            <div className="row">
                <div className="col-lg-12">
                    
                    
                    <div className="card">
                        <div className="card-image">
                            <img className="img-fluid" src="analysis.png" alt="alternative"/>
                        </div>
                        <div className="card-body">
                            <h3 className="card-title">Analysis</h3>
                            <p>Get a tailored report analyzing the potential fit for selected softwares</p>
                            <ul className="list-unstyled li-space-lg">
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body">Cloud</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body">On Premise</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body">Embedded</div>
                                </li>
                            </ul>
                        </div>
                        <div className="button-container">
                            <a className="btn-solid-reg page-scroll" href="#callMe">DETAILS</a>
                        </div> 
                    </div>
                    
                    <div className="card">
                        <div className="card-image">
                            <img className="img-fluid" src="question.png" alt="alternative"/>
                        </div>
                        <div className="card-body">
                            <h3 className="card-title">Goal Setting</h3>
                            <p>Align your business goals with an IT strategy that works for you</p>
                            <ul className="list-unstyled li-space-lg">
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body">Short Term</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body">Long Term</div>
                                </li>
                            </ul>
                        </div>
                        <div className="button-container">
                            <a className="btn-solid-reg page-scroll" href="#callMe">DETAILS</a>
                        </div> 
                    </div>

                    <div className="card">
                        <div className="card-image">
                            <img className="img-fluid" src="agreement.png" alt="alternative"/>
                        </div>
                        <div className="card-body">
                            <h3 className="card-title">Policy Setting</h3>
                            <p>Protect your business with sensible IT policies designed for you.</p>
                            <ul className="list-unstyled li-space-lg">
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body">Data</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body">Access</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body">Technology</div>
                                </li>
                            </ul>
                        </div>
                        <div className="button-container">
                            <a className="btn-solid-reg page-scroll" href="#callMe">DETAILS</a>
                        </div> 
                    </div>

                </div> 
            </div>
            <div className="row">
                <div className="col-lg-12">
                    
                    
                    <div className="card">
                        <div className="card-image">
                            <img className="img-fluid" src="dynamic.png" alt="alternative"/>
                        </div>
                        <div className="card-body">
                            <h3 className="card-title">Web Development</h3>
                            <p>Build trust with customers by having an excellent website in place</p>
                            <ul className="list-unstyled li-space-lg">
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body">Full Stack</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body">E-Commerce</div>
                                </li>
                            </ul>
                        </div>
                        <div className="button-container">
                            <a className="btn-solid-reg page-scroll" href="#callMe">DETAILS</a>
                        </div> 
                    </div>
                    
                    <div className="card">
                        <div className="card-image">
                            <img className="img-fluid" src="launch.png" alt="alternative"/>
                        </div>
                        <div className="card-body">
                            <h3 className="card-title">Embedded Systems</h3>
                            <p>Circuits, transistors, sensors, oh my! The exciting world of IoT awaits</p>
                            <ul className="list-unstyled li-space-lg">
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body">Raspberry Pi</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body">Arduino</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body">Web Services</div>
                                </li>
                            </ul>
                        </div>
                        <div className="button-container">
                            <a className="btn-solid-reg page-scroll" href="#callMe">DETAILS</a>
                        </div> 
                    </div>

                    <div className="card">
                        <div className="card-image">
                            <img className="img-fluid" src="programming.png" alt="alternative"/>
                        </div>
                        <div className="card-body">
                            <h3 className="card-title">General</h3>
                            <p>Whether it's exploring an idea or enhancing an existing one, we are here to help</p>
                            <ul className="list-unstyled li-space-lg">
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body">POC</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body">MVP</div>
                                </li>
                            </ul>
                        </div>
                        <div className="button-container">
                            <a className="btn-solid-reg page-scroll" href="#callMe">DETAILS</a>
                        </div> 
                    </div>

                </div> 
            </div>  
        </div> 
      </div>

      <div id="callMe" className="form-1">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="text-container">
                        <h2 className="white">Request Assistance By Filling And Submitting The Form</h2>
                        <p className="white">Fill in the form and send it to us and we'll get right back with a call or email to help you decide what service works.</p>
                        <ul className="list-unstyled li-space-lg white">
                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body">It's very easy just fill in the form so we can call or email</div>
                            </li>
                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body">When contacting we'll require some info about the company</div>
                            </li>
                            <li className="media">
                                <i className="fas fa-square"></i>
                                <div className="media-body">Don't hesitate to contact us for any questions or inquiries</div>
                            </li>
                        </ul>
                    </div>
                </div> 
                <div className="col-lg-6">
                   
                    <form onSubmit={handleRequest} id="callMeForm" data-toggle="validator" data-focus="false">
                        <div className="form-group">
                            <input type="text" name="name" value={name} onChange={e => setName(e.target.value)} className="form-control-input" id="lname" required/>
                            <label className="label-control" for="lname">Name</label>
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group">
                            <input type="text" name="phone" value={phone} onChange={e => setPhone(e.target.value)} className="form-control-input" id="lphone" required/>
                            <label className="label-control" for="lphone">Phone</label>
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group">
                            <input type="email" name="email" value={email} onChange={e => setEmail(e.target.value)} className="form-control-input" id="lemail" required/>
                            <label className="label-control" for="lemail">Email</label>
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group">
                            <select name="service" value={service} onChange={e => setService(e.target.value)} className="form-control-select" id="lselect" required>
                                <option className="select-option" value="" disabled selected>Interested in...</option>
                                <option className="select-option" value="Software Analysis">Software Analysis</option>
                                <option className="select-option" value="Goal Setting">Goal Setting</option>
                                <option className="select-option" value="Policy Setting">Policy Setting</option>
                                <option className="select-option" value="Web Development">Web Development</option>
                                <option className="select-option" value="Embedded Systems">Embedded Systems</option>
                                <option className="select-option" value="General">General</option>
                            </select>
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group">
                            <textarea name="message" value={message} onChange={e => setMessage(e.target.value)} className="form-control-textarea" id="cmessage" required></textarea>
                            <label className="label-control" for="cmessage">Your message</label>
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="form-control-submit-button">REQUEST</button>
                        </div>
                        <div className="form-message">
                            <div id="lmsgSubmit" className="h3 text-center hidden"></div>
                        </div>
                    </form>
                    
                </div> 
            </div> 
        </div> 
      </div> 


      <div id="about" className="basic-2">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h2>The Team</h2>
                    <p className="p-heading">Part consulting, part weird science, all Arreis. We believe that culture is an important piece of the business puzzle and can often be the determining factor in the adoption of new technology. Our goal is to help small businesses and entrepreneurs navigate the wide world of IT to find solutions that fit. Founded by a West African immigrant, we understand what it means to adjust to new things and we take a creative approach to our solutioning.</p>
                </div> 
            </div> 
            <div className="row">
                <div className="col-lg-12">

                    
                    <div className="team-member">
                        <div className="image-wrapper">
                            <img className="img-fluid" src="me-grey.JPG" alt="alternative"/>
                        </div> 
                        <p className="p-large">Sheikh Kamarah Jr.</p>
                        <p className="job-title">CEO</p>
                        <span className="social-icons">
                            <span className="fa-stack">
                                <a href="https://www.linkedin.com/in/sheikh-kamarah-jr-msis-6204aa61">
                                    <span class="hexagon"></span>
                                    <FaLinkedin/>
                                </a>
                            </span>
                        </span>
                    </div> 
                    

                </div> 
            </div> 
        </div> 
      </div>


      <div className="copyright">
          <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                      <p className="p-small">Copyright © 2020 <a href="https://arreistech.com">Arreis Technologies</a>, a TKS Brand</p>
                  </div> 
              </div> 
          </div> 
      </div>

    </div>
  );
}

export default App;
